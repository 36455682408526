<template>
  <div class="skeleton-profile-bonuses">
    <div class="skeleton-profile-bonuses__desktop">
      <div class="skeleton-profile-bonuses__layout">
        <Skeletor class="skeleton-profile-bonuses__info" as="div" />
        <Skeletor class="skeleton-profile-bonuses__promo" as="div" />
      </div>

      <Skeletor class="skeleton-profile-bonuses__tabs" as="div" />

      <Skeletor class="skeleton-profile-bonuses__summary-info" as="div" />
    </div>

    <div class="skeleton-profile-bonuses__mobile">
      <Skeletor class="skeleton-profile-bonuses__info" as="div" />

      <div class="skeleton-profile-bonuses__theme">
        <Skeletor class="skeleton-profile-bonuses__theme-item" as="div" />
        <Skeletor class="skeleton-profile-bonuses__theme-item" as="div" />
      </div>

      <Skeletor class="skeleton-profile-bonuses__tabs" as="div" />

      <Skeletor class="skeleton-profile-bonuses__summary-info" as="div" />
    </div>

    <div class="skeleton-profile-bonuses__content">

      <Skeletor class="skeleton-profile-bonuses__tabs" as="div" />

      <div class="skeleton-profile-bonuses__active-bonuses">
        <Skeletor
          v-for="n in ACIVE_BONUSES_COUNT"
          :key="n"
          class="skeleton-profile-bonuses__active-bonus"
          as="div" />
      </div>

      <Skeletor class="skeleton-profile-bonuses__title" as="div" />

      <Skeletor class="skeleton-profile-bonuses__text" as="div" />

      <Skeletor class="skeleton-profile-bonuses__items-title" as="div" />

      <Skeletor class="skeleton-profile-bonuses__steps" as="div" />

      <div class="skeleton-profile-bonuses__items">
        <Skeletor
          v-for="n in BONUSES_ITEMS_COUNT"
          :key="n"
          class="skeleton-profile-bonuses__item"
          as="div" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  const ACIVE_BONUSES_COUNT = 3;
  const BONUSES_ITEMS_COUNT = 4;

</script>

<style src="~/assets/styles/components/skeleton-preloader/profile-bonuses-page.scss" lang="scss" />
